import React from "react";
import ReactDom from "react-dom";

import { Rating, Header, Container } from 'semantic-ui-react'

import { Map, Marker } from "pigeon-maps"

import {client} from "./resources"

let MapView = (props) => <div>
    <Map height={300} defaultCenter={[props.lat, props.lng]} defaultZoom={14} touchEvents={false} mouseEvents={false}>
        <Marker width={50} anchor={[props.lat, props.lng]} />
    </Map></div>;

export class DetailView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          fields: {}
        };   
    }
    componentDidMount() {
        let entityId = this.props.match.params.entityId;
        client.getEntry(entityId)
        .then((entry) => {
            // logs the entry metadata
            console.log(entry.sys);

            // logs the field with ID title
            console.log(entry.fields.location);
            this.setState({
                isLoaded: true,
                fields: entry.fields,
            });
            console.log("refreshe");
        });
    }
    render() {
        return !this.state.isLoaded ? <p>Please wait</p> : <div>
           <h1>{this.state.fields.name}</h1>
           <div style={{display: "inline-flex", width: "100%"}}>
                <div><Header sub>Bewertung</Header><Rating icon='star' rating={this.state.fields.rating} maxRating={5} /></div>
                <div style={{width: "20px"}}/>
                <div><Header sub>Preis/Leistung</Header><Rating icon='star' rating={this.state.fields.priceQuality} maxRating={5} /></div>
            </div>
           <Header as='h3'>Beschreibung</Header>
           {this.state.fields.review}
           <Header as='h3'>Standort</Header>
           <MapView lat={this.state.fields.location.lat} lng={this.state.fields.location.lon} />
        </div>
    }
}