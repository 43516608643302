import React from "react";
import ReactDom from "react-dom";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter
} from "react-router-dom"

import { Container, Grid, Segment, Icon } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

import {DetailView} from './detailView';
import {OverView} from './overView';

import logoUrl from "url:../assets/logo.png";

/*let AppHeader = () => <div style={{width: "100%", "textAlign": "center", padding: "1.5em"}}>
    <img style={{height: "2em"}}src={logoUrl}></img>
</div>;*/

class AppHeader extends React.Component {
    render() {
      const { match, location, history } = this.props;
      let isAtBase = (location.pathname == "/");
  
      return <Grid columns='equal' textAlign='center' verticalAlign='middle'>
      <Grid.Column>
        {!isAtBase && <Link to="/"><Icon name="angle left" size="large"/></Link>}
      </Grid.Column>
      <Grid.Column width={8}>
        <img style={{height: "2em", marginTop: "1.5em", marginBottom: "1.5em"}}src={logoUrl}></img>
      </Grid.Column>
      <Grid.Column>
        
      </Grid.Column>
    </Grid>;
    }
  }
let AppHeaderWithRouter = withRouter(AppHeader);

let Home = () => <h1>Hello World</h1>;

const App = () => <div>
    <Router>
        <AppHeaderWithRouter/>
    <Container>
        <Switch>
        <Route path="/entity/:entityId" component={DetailView}/>
        <Route path="/">
            <OverView/>
        </Route>
    </Switch>
    </Container>
</Router></div>;

ReactDom.render(<App />, document.getElementById("app"));