import React from "react";
import { Map, Marker } from "pigeon-maps";
import { Button, Icon,Image, Item, Rating } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';

import {client} from "./resources"
import {getDistanceFromLatLng} from "./helper"


function distanceTo(user, entity) {
    let distance = getDistanceFromLatLng(entity.lat, entity.lon, user.lat,user.lng);
    if (distance < 1) {
        return Math.round(distance*1000) + " m";
    } else {
        return Math.round(distance) + " km";
    }
}

export class OverView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          coords: {
              lat: 0.0,
              lng: 0.0
          },
          items: [],
          isLoaded: false
        };   
    }

    componentDidMount() {
        client.getEntries({
            content_type: 'reviewEntity'
        }).then((response) => {
            this.setState({
                isLoaded: true,
                items: response.items.sort((a,b) => {
                    let userCoords = this.state.coords;
                    return getDistanceFromLatLng(a.fields.location.lat, a.fields.location.lon,userCoords.lat,userCoords.lng) - getDistanceFromLatLng(b.fields.location.lat, b.fields.location.lon,userCoords.lat,userCoords.lng);
                })
            })
            console.log(response);
        });

        navigator.geolocation.getCurrentPosition((position) => {
            let coords = position.coords;
            console.log("update");
            this.setState({
                coords: {
                    lat: coords.latitude,
                    lng: coords.longitude
                },
                items: this.state.items.sort((a,b) => {
                    let userCoords = coords;
                    return getDistanceFromLatLng(a.fields.location.lat, a.fields.location.lon,userCoords.lat,userCoords.lng) - getDistanceFromLatLng(b.fields.location.lat, b.fields.location.lon,userCoords.lat,userCoords.lng);
                })
            });
        }, () => {});
    }
    render() {
        return !this.state.isLoaded ? <p>Please wait</p> : <div>
            <Item.Group divided>
                {this.state.items.map((value, index) => {
                    return <Item key={index}>
                    <Item.Content>
                        <Item.Header as='a'>{value.fields.name}</Item.Header>
                        <Item.Meta><Rating icon='star' rating={value.fields.rating} maxRating={5} />&nbsp;&nbsp;&nbsp;{distanceTo(this.state.coords,value.fields.location)} entfernt</Item.Meta>
                        <Item.Description>
                        {value.fields.review}
                        </Item.Description>
                        <Item.Extra>
                        <Button as={NavLink} to={"/entity/" + value.sys.id} floated='right'>Mehr</Button>
                        </Item.Extra>
                    </Item.Content>
                    </Item>
                })}
            </Item.Group>
        </div>
    }
} 